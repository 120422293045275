<template>
<div class="v-window v-item-group theme--light v-tabs-items">
  <div class="v-window__container" style="">
    <div class="v-window-item portfolio-wrapper portfolio-list mt--60 v-window-item--active">
      <div id="animated-thumbnials" class="row row--5">
        <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/tarsashazak" class="">
              <img src="../../assets/img/567x600px/567x600px-Tarsashaz.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/tarsashazak" class="">Társasházak</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/csarnokepites" class="">
              <img src="../../assets/img/567x600px/567x600px-Csarnok.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/csarnokepites" class="">Csarnok építése</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/lakatos-munkak" class="">
              <img src="../../assets/img/567x600px/567x600px-Lakatos.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/lakatos-munkak" class="">Lakatos munkák</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      

        <div id="animated-thumbnials" class="row row--5">
        <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/belso-iroda-atalakitasok" class="">
              <img src="../../assets/img/567x600px/567x600px-Iroda.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/belso-iroda-atalakitasok" class="">Belső iroda átalakítások</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/butorasztalos-munkak" class="">
              <img src="../../assets/img/567x600px/567x600px-Asztalos.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/butorasztalos-munkak" class="">Bútorasztalos munkák </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/nyilaszarok-gyartasa-csereje" class="">
              <img src="../../assets/img/567x600px/567x600px-Nyilaszaro.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/nyilaszarok-gyartasa-csereje" class="">Nyílászárók gyártása, cseréje</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div id="animated-thumbnials" class="row row--5">
        <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/gipszkarton-allmennyezet" class="">
              <img src="../../assets/img/567x600px/567x600px-Gipszkarton.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/gipszkarton-allmennyezet" class="">Gipszkarton, állmennyezet </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/gyenge-es-erosaramu-szakipari-munkak" class="">
              <img src="../../assets/img/567x600px/567x600px-Aram.jpeg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/gyenge-es-erosaramu-szakipari-munkak" class="">Gyenge és erős áramú szakipari munkák </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/melyepites" class="">
              <img src="../../assets/img/567x600px/567x600px-Melyepites.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/melyepites" class="">Mélyépítés </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="animated-thumbnials" class="row row--5">
        <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/okosotthon" class="">
              <img src="../../assets/img/567x600px/567x600px-SmartHome.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/okosotthon" class="">Okosotthon</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/bontas" class="">
              <img src="../../assets/img/567x600px/567x600px-Bontas.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/bontas" class="">Bontás</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div class="col-sm-6 col-md-4 col-lg-4 col-12"> 
          <div class="portfolio_style--3">
            <div class="thumb">
              <a href="/zold-felulet-epitese" class="">
              <img src="../../assets/img/567x600px/567x600px-Kert.jpg" /></a>
              <div class="port-hover-action">
                <div class="content">
                  <h3>
                    <a href="/zold-felulet-epitese" class="">Zöld felület építése </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {

      };
    },
  };
</script>
