<template>
  <!-- Footer -->
  <footer class="-rn-footer" id="footer">
    <div class="rn-footer-wrapper footer">
      <v-container fluid>
        <v-row>
          <v-col lg="12">
            <div class="footer-top">
              <div class="content">
                <span>Keressen minket!</span>
                <h4>Elérhetőségeink</h4>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-wrapper ptb--100">
        <v-container fluid>
          <v-row>
            <!-- Start Single Wedget -->
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Telephelyünk</h3>
                <div class="content">
                  <p>
                    Helsinki út 63. <br />
                    1201 Budapest
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Kapcsolat</h3>
                <div class="content">
                  <div class="address">
                    <p>
                      <a href="mailto:info@epitonet.hu">info@epitonet.hu</a>
                    </p>
                    <p><a href="tel:3670123456">+3670123456</a></p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Hasznos Linkek</h3>
                <div class="content">
                  <ul class="quick-link">
                    <li v-for="(link, i) in quickLink" :key="i">
                      <router-link :to="link.RouteName">{{
                        link.name
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
            <!-- <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <a href="/palyazat">
                  <img src="../../assets/img/vekop/vekop-alap.png"/>                
                </a>
              </div>
            </v-col> -->
          </v-row>
        </v-container>
        <div id="page_fixed">
          <a href="/palyazat"><img src="../../assets/img/vekop/vekop-alap.png" alt="Széchényi 2020 - Befektetés a jövőbe"></a>
        </div>
      </div>
    </div>

    <FooterTwo />
  </footer>
  <!--// Footer -->
</template>

<script>
  import FooterTwo from "./FooterTwo";
  export default {
    components: {
      FooterTwo,
    },
    data() {
      return {
        quickLink: [
          {
            name: "Kezdőlap",
            RouteName: "",
          },
          {
            name: "Portfolio",
            RouteName: "/#portfolio",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
#page_fixed {
    display: block;
    position: fixed;
    width: 300px;
    bottom: 0;
    right: 0;
    z-index: 99;
}
</style>
