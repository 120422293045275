<template>
  <div class="about-page">
    <div class="rn-story-area d-flex bg_color--3" id="about">
      <div
        class="rn-story-left w-50 rn-story-bg-wrapper"
        :style="{ backgroundImage: 'url(' + aboutSrc + ')' }"
      ></div>
      <div class="rn-story-right w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              Rólunk
            </h2>
            <p>
             Cégünk megalakulása óta folyamatos növekedésre törekszik, mind állományi létszámát, mind az eszközeinek bővítésére fordított beruházások tekintetében. Mindezeknek köszönhetően széles eszköz és gépparkkal tudjuk partnereink megbízásait teljesíteni. Mivel a megelégedettséghez hitünk szerint a kiváló eszközpark mellett elsősorban kollégáink megfelelő szakértelme és szakmaszeretete szükséges, ezért különös figyelmet fordítunk arra, hogy minden nálunk dolgozó kolléga saját szakterületén széleskörű ismeretekkel rendelkezzen, mind a régi, mind az új technológiák, valamint az új anyagok és azok felhasználásának tekintetében.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
  </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        aboutSrc: require("../../assets/img/860x750px/860x750px-Terko.jpg"),
        skillSrc: require("../../assets/img/portfolio/my-skill.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .about-page {
    .rn-skill-right {
      @media only screen and (max-width: 767px) {
        order: 2;
      }
    }
  }
</style>
