<template>
  <!-- Start Single Slider -->
  <div>
    <VueSlickCarousel
      v-bind="settings"
      class="slider-activation-with-slick rn-slick-dot dot-light mb--0"
    >
      <div
        class="slider--8 fullscreen d-flex align-center justify-center fullscreen bg_image rfanimation-style--1"
        v-for="(slider, i) in sliderContent"
        :key="i"
        :style="{ backgroundImage: 'url(' + slider.src + ')' }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="content text-center">
                <h2 class="heading-title">{{ slider.title }}</h2>
                <p class="description">
                  {{ slider.desc }}
                </p>
                <div class="slide-btn mt-10">
                  <router-link class="rf-btn" to="/#footer"
                    >Kapcsolat</router-link
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";

  export default {
    components: { VueSlickCarousel },
    data() {
      return {
        sliderContent: [
          {
            src: require("../../assets/img/1720x750px/1720x750px-Csarnok.jpg"),
            title: "Csarnok",
            desc: `A csarnoképítést precízen és gyorsan kivitelezzük, és az elkészült csarnokot akár kulcsrakészen vagy csak szerkezetkészen adjuk át. Ipari csarnokok-, raktárcsarnokok-, és egyéb csarnokok építését vállaljuk alacsony árszint magas minőség mellett. `,
          },
          {
            src: require("../../assets/img/1720x750px/1720x750px-Iroda.jpg"),
            title: "Iroda",
            desc: ` Generál kivitelezőként teljes körű lakásfelújítást, iroda és más üzleti helyiségek átalakítását vállaljuk. Csapatunk olyan tapasztalt mérnökökből, műszaki vezetőkből és szakemberekből állnak, akik mind specialisták a maguk szakterületükön.`,
          },
          {
            src: require("../../assets/img/1720x750px/1720x750px-SmartHome.jpg"),
            title: "Okosotthon",
            desc: ` Az okos otthon az igényeinkhez tökéletesen idomuló automatizált rendszer jelent, melyben az elektromos eszközök a szokásainkhoz alkalmazkodva működnek, a mi kényelmünket szolgálva. `,
          },
        ],
        settings: {
          fade: true,
          dots: true,
          arrows: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 20,
        },
      };
    },
  };
</script>

<style lang="scss">
  .slick-slide {
    img {
      display: block;
      width: 100%;
    }
  }
</style>
